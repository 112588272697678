import React, {FC, Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import Animated, {
  FadeIn,
  FadeInRight,
  FadeOut,
  FadeOutRight,
  LinearTransition,
} from 'react-native-reanimated';

import Avatar from '@/components/Avatar';
import Divider from '@/components/Divider';
import Input from '@/components/Input';
import Pill from '@/components/Pill';
import SpinLoader from '@/components/SpinLoader';
import Text, {TextProps} from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useDebouncedSearch} from '@/hooks/useDebouncedSearch';
import {useDebouncedValue} from '@/hooks/useDebouncedValue';
import {useUsersSearchQuery} from '@/queries/search';
import {useFollowedCollectorsPlaylists} from '@/screens/Library/hooks/useFollowedPlaylists';
import {useThemedStyles} from '@/theme';
import {GENRES} from '@/types/feed';
import {IBaseUser} from '@/types/user';
import {getUniqueItemsByFieldName, isNotNil} from '@/utils/functions';
import {isNative} from '@/utils/platform';
import {getUserDisplayName} from '@/utils/user';

import {styles} from './ChannelPills.style';

interface IProps {
  spiedUserId?: string;
  onUserSelect: (userId: string | undefined) => void;
  onSearchFocus?: () => void;
  onSearchBlur?: () => void;
  scrollToTop?: React.RefObject<() => void>;
}

interface IChannelPill {
  key: string;
  type: 'forYou' | 'spyButton' | 'user' | 'genre';
  user?: IBaseUser;
  text?: TextProps;
}

const AnimationLayout = LinearTransition.duration(200);

const genrePills = [
  ...GENRES.map(genre => ({
    key: genre.userId,
    type: 'genre',
    text: {
      children: genre.text,
    },
  })),
] as IChannelPill[];

const ChannelPills: FC<IProps> = ({
  spiedUserId: _spiedUserId,
  onUserSelect: _onUserSelect,
  onSearchFocus,
  onSearchBlur,
  scrollToTop,
}) => {
  const style = useThemedStyles(styles);
  const scrollViewRef = useRef<Animated.ScrollView | null>(null);

  // We use copy of spiedUserId prop, so we can update local state immediately after pill press and delay updating parent value using requestAnimationFrame.
  // That way we are delaying replacing whole feed in ChannelsScreen in one render cycle, which helps pills animation work nice and smooth.
  const [spiedUserId, setSpiedUserId] = useState(_spiedUserId);

  useEffect(() => {
    setSpiedUserId(_spiedUserId);
  }, [_spiedUserId]);

  const onUserSelect = (_userId?: string) => {
    if (_userId === spiedUserId) {
      scrollToTop?.current?.();
      return;
    }

    setSpiedUserId(_userId);
    scrollViewRef.current?.scrollTo({x: 0, animated: true});

    requestAnimationFrame(() => {
      _onUserSelect(_userId);
    });
  };

  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState('');

  const {searchDebounced, clearSearch} = useDebouncedSearch({
    search,
    setSearch,
  });
  const isSearchingEnabled = searchDebounced.length > 1;

  const [spiedUsers, setSpiedUsers] = useState<IBaseUser[]>([]);

  const {followedCollectorsPlaylists} = useFollowedCollectorsPlaylists();
  const followedUsers = followedCollectorsPlaylists.map(p => p.followedUser);

  const {
    users: searchedUsers,
    query: {isLoading: isSearching, isPlaceholderData},
  } = useUsersSearchQuery(searchDebounced);

  const searchResultsUsers = isSearchingEnabled ? searchedUsers : followedUsers;

  const closeSearch = () => {
    setShowSearch(false);
    clearSearch();
  };

  const sortedPills = useMemo(() => {
    const forYouPill = {
      key: 'forYou',
      type: 'forYou',
    };
    const spyButtonPill = {
      key: 'spyButton',
      type: 'spyButton',
    };

    const userPills = spiedUsers.map(user => ({
      key: user.id,
      type: 'user',
      user,
    }));

    const pills = [...userPills, ...genrePills];
    const selectedPill = pills.find(g => g.key === spiedUserId);
    const unselectedPills = pills.filter(g => g.key !== spiedUserId);

    return [selectedPill, forYouPill, spyButtonPill, ...unselectedPills].filter(
      isNotNil,
    );
  }, [spiedUsers, spiedUserId]) as IChannelPill[];

  const renderPill = (pill: IChannelPill) => {
    if (pill.type === 'forYou') {
      return (
        <Pill
          style={style.pill}
          onPress={() => onUserSelect(undefined)}
          text={{
            id: 'feed.title',
          }}
          isSelected={!spiedUserId}
        />
      );
    }

    if (pill.type === 'spyButton') {
      return (
        <Pill
          style={style.pill}
          onPress={() => setShowSearch(true)}
          text={{
            id: 'channels.spy',
          }}
        />
      );
    }

    if (pill.type === 'genre') {
      return (
        <Pill
          style={style.pill}
          onPress={() => {
            onUserSelect(pill.key);
          }}
          text={pill.text}
          isSelected={spiedUserId === pill.key}
        />
      );
    }

    if (pill.type === 'user' && pill.user) {
      const user = pill.user;

      return (
        <Pill
          style={[style.pill, style.userPill]}
          onPress={() => onUserSelect(user.id)}
          prefixComponent={
            <Avatar id={user.id} url={user.avatarUrl} size={spacing.l} />
          }
          text={{
            children: getUserDisplayName(user),
          }}
          isSelected={spiedUserId === user.id}
        />
      );
    }

    return null;
  };

  const [showSearchLoader] = useDebouncedValue(
    isSearchingEnabled && (isSearching || isPlaceholderData),
    500,
  );

  return (
    <View style={style.headerControls}>
      {showSearch ? (
        <Animated.View
          style={style.searchContainer}
          entering={FadeInRight}
          exiting={FadeOutRight}>
          <Input
            style={style.input}
            value={search}
            onChangeText={setSearch}
            autoFocus
            placeholderId="channels.spy.placeholder"
            prefixIcon={{name: 'userSearch', provider: 'custom'}}
            suffixComponent={showSearchLoader && <SpinLoader size={20} />}
            onFocus={onSearchFocus}
            onBlur={() => {
              if (isNative) {
                closeSearch();
              }
              onSearchBlur?.();
            }}
          />
          <TransparentButton
            style={style.cancelButton}
            onPress={closeSearch}
            text={{id: 'cancel', size: 'xs'}}
          />
        </Animated.View>
      ) : (
        <Animated.ScrollView
          ref={scrollViewRef}
          entering={FadeIn}
          exiting={FadeOut}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={style.pillsScrollContent}>
          {sortedPills.map(pill => (
            <Animated.View key={pill.key} layout={AnimationLayout}>
              {renderPill(pill)}
            </Animated.View>
          ))}
        </Animated.ScrollView>
      )}
      {showSearch &&
        (searchResultsUsers.length > 0 ||
          (isSearchingEnabled && !isSearching)) && (
          <Animated.View
            entering={FadeIn.duration(150)}
            exiting={FadeOut.duration(150)}
            style={style.searchResults}>
            {!isSearchingEnabled && (
              <View style={style.searchItem}>
                <Text weight="semibold">Followed</Text>
              </View>
            )}
            {searchResultsUsers.slice(0, 5).map((user, index) => (
              <Fragment key={user.id}>
                {index > 0 && (
                  <View style={style.divider}>
                    <Divider />
                  </View>
                )}
                <TouchableOpacity
                  style={style.searchItem}
                  onPress={() => {
                    setSpiedUsers(currentSpiedUsers =>
                      getUniqueItemsByFieldName(
                        [user, ...currentSpiedUsers],
                        'id',
                      ),
                    );
                    onUserSelect(user.id);
                    closeSearch();
                  }}>
                  <Avatar id={user.id} url={user.avatarUrl} size={spacing.l} />
                  <Text size="s" flex numberOfLines={1}>
                    {getUserDisplayName(user)}
                  </Text>
                </TouchableOpacity>
              </Fragment>
            ))}

            {isSearchingEnabled && searchedUsers.length === 0 && (
              <View style={style.emptyState}>
                <Text align="center" size="xs" id="channels.spy.empty" />
              </View>
            )}
          </Animated.View>
        )}
    </View>
  );
};

export default React.memo(ChannelPills);
