import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {ScrollView, TouchableOpacity, View} from 'react-native';

import Divider from '@/components/Divider';
import Icon from '@/components/Icon/Icon';
import IconButton from '@/components/IconButton';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {UsersMenu} from '@/components/UsersMenu';
import {chainsById} from '@/constants/chains';
import {config} from '@/constants/config';
import {externalLinks} from '@/constants/externalLinks';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useResponsive} from '@/hooks/useResponsive';
import {useExternalSigner} from '@/modules/ExternalWallet';
import {useThemedStyles} from '@/theme';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {openLinkSafe} from '@/utils/linking';
import {isWeb} from '@/utils/platform';
import {getUserSlug} from '@/utils/user';

import {styles} from './DrawerMenu.style';
import {useDrawer} from './useDrawer';

const DrawerMenu: FC = () => {
  const style = useThemedStyles(styles);
  const {isMobile} = useResponsive();
  const {closeDrawer, onDrawerNavigation} = useDrawer();
  const navigation = useNavigation<RootStackNavigationParams>();
  const activeUser = useActiveUser();

  const {activeNetwork, connector} = useExternalSigner();

  const openCollectorProfile = () => {
    if (activeUser) {
      onDrawerNavigation(() =>
        navigation.navigate(Routes.MainNavigation, {
          screen: Routes.Collector,
          params: {
            slug: getUserSlug(activeUser),
          },
        }),
      );
    }
  };

  const openArtistProfile = () => {
    if (activeUser?.artistProfile) {
      onDrawerNavigation(() =>
        navigation.navigate(Routes.MainNavigation, {
          screen: Routes.Artist,
          params: {
            slugOrId: activeUser.artistProfile!.slug!,
          },
        }),
      );
    }
  };

  const openExternalLink = (uri: string) => () => {
    openLinkSafe(uri);
    closeDrawer();
  };

  if (!isMobile) {
    return null;
  }

  return (
    <View style={style.root}>
      <UsersMenu />
      <Space ph="xs">
        <Divider />
      </Space>
      <ScrollView
        style={style.scrollContainer}
        contentContainerStyle={style.contentContainer}
        showsVerticalScrollIndicator={false}
        bounces>
        <Space pb="xs">
          {activeUser && (
            <TouchableOpacity style={style.item} onPress={openCollectorProfile}>
              <View style={style.iconWrapper}>
                <Icon name="user" provider="custom" />
              </View>
              <Text
                size="s"
                id={
                  activeUser?.artistProfile ? 'menu.collector' : 'menu.profile'
                }
              />
            </TouchableOpacity>
          )}
          {activeUser?.artistProfile && (
            <TouchableOpacity style={style.item} onPress={openArtistProfile}>
              <View style={style.iconWrapper}>
                <Icon name="artist" provider="custom" />
              </View>
              <Text size="s" id="menu.artist" />
            </TouchableOpacity>
          )}
          {!!activeUser && (
            <TouchableOpacity
              style={style.item}
              onPress={() => {
                onDrawerNavigation(() =>
                  navigation.navigate(Routes.MainNavigation, {
                    screen: Routes.Wallets,
                  }),
                );
              }}>
              <View style={style.iconWrapper}>
                <Icon name="wallet" provider="custom" />
              </View>
              <Text size="s" id="wallets.title" numberOfLines={1} flex />
            </TouchableOpacity>
          )}
          {activeUser?.artistProfile && (
            <TouchableOpacity
              style={style.item}
              onPress={() => {
                onDrawerNavigation(() =>
                  navigation.navigate(Routes.MainNavigation, {
                    screen: Routes.TipsSettings,
                  }),
                );
              }}>
              <View style={style.iconWrapper}>
                <Icon name="tip" provider="custom" />
              </View>
              <Text size="s" id="tipsSettings.title" numberOfLines={1} flex />
            </TouchableOpacity>
          )}
          {activeUser && !isWeb && (
            <TouchableOpacity
              style={style.item}
              onPress={() => {
                onDrawerNavigation(() =>
                  navigation.navigate(Routes.MainNavigation, {
                    screen: Routes.NotificationSettings,
                  }),
                );
              }}>
              <View style={style.iconWrapper}>
                <Icon name="notifications" provider="custom" size={24} />
              </View>
              <Text
                size="s"
                id="notificationSettings.title"
                numberOfLines={1}
                flex
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            style={style.item}
            onPress={() =>
              onDrawerNavigation(() =>
                navigation.navigate(Routes.MainNavigation, {
                  screen: Routes.ThemesSettings,
                }),
              )
            }>
            <View style={style.iconWrapper}>
              <Icon name="palette" provider="custom" />
            </View>
            <Text size="s" id="themesSettings.title" />
          </TouchableOpacity>
          <TouchableOpacity
            style={style.item}
            onPress={() => {
              onDrawerNavigation(() =>
                navigation.navigate(Routes.MainNavigation, {
                  screen: Routes.ActivityFeed,
                }),
              );
            }}>
            <View style={style.iconWrapper}>
              <Icon name="nftActivity" provider="custom" />
            </View>
            <Text size="s" id="activityFeed.title" numberOfLines={1} flex />
          </TouchableOpacity>
          <TouchableOpacity
            style={style.item}
            onPress={() => {
              onDrawerNavigation(() =>
                navigation.navigate(Routes.MainNavigation, {
                  screen: Routes.DropOnSpinamp,
                }),
              );
            }}>
            <View style={style.iconWrapper}>
              <Icon name="upload" provider="custom" />
            </View>
            <Text size="s" id="dropOnSpinamp.title" numberOfLines={1} flex />
            <View style={style.newLabel}>
              <Text size="xs">New</Text>
            </View>
          </TouchableOpacity>

          <Space pl="xs" mt="s" mb="xxs">
            <Text weight="semibold" id="menu.contact" size="s" />
          </Space>

          {externalLinks.spinamp.map(link => (
            <TouchableOpacity
              key={link.url}
              style={style.item}
              onPress={openExternalLink(link.url)}>
              <View style={style.iconWrapper}>
                <Icon {...link.icon} />
              </View>
              <Text size="s" id={link.textId} numberOfLines={1} flex>
                {link.text}
              </Text>
            </TouchableOpacity>
          ))}
        </Space>

        <Space flex />

        <Space style={style.externalLinkIcons}>
          {externalLinks.contact.map(link => (
            <IconButton
              key={link.url}
              icon={link.icon}
              onPress={openExternalLink(link.url)}
            />
          ))}
        </Space>

        {!isWeb && config.ENV !== 'production' && !!connector && (
          <Space ph="s" mt="xxs">
            <Text size="xxs">{connector}</Text>
          </Space>
        )}

        {!isWeb && config.ENV !== 'production' && !!activeNetwork && (
          <Space ph="s" mt="xxs">
            <Text size="xxs">Chain: {chainsById[activeNetwork]?.name}</Text>
          </Space>
        )}

        {!isWeb && config.ENV !== 'production' && (
          <Space ph="s" mt="xxs" mb="xs">
            <Text size="xxs">{`Env: ${config.ENV}`}</Text>
          </Space>
        )}
      </ScrollView>
    </View>
  );
};

export default React.memo(DrawerMenu);
