import {QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import React, {useEffect, useState} from 'react';
import {StatusBar} from 'react-native';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import AppBootstrap from '@/components/AppBootstrap';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';
import {GlobalModalProvider} from '@/components/GlobalModal/GlobalModal';
import OnboardingModal from '@/components/OnboardingModal';
import Translation from '@/components/Translation/Translation';
import {ExternalWalletProvider} from '@/modules/ExternalWallet';
import RootNavigation from '@/navigation/RootNavigation';
import {registerPlayer} from '@/services/playbackService';
import {queryClient, setupReactQuery} from '@/services/reactQuery';
import {setMutationDefaults} from '@/services/reactQuery/setMutationDefaults';
import {Sentry} from '@/services/sentry';
import {TrackPlayer} from '@/services/trackPlayer';
import {store, persistor} from '@/store';
import ThemeProvider from '@/theme/ThemeProvider';
import {subscribeToBackgroundNotifications} from '@/utils/notifications';
import {isAndroid, isWeb} from '@/utils/platform';

registerPlayer(store);

setMutationDefaults(queryClient, store);

Sentry.initialize();

subscribeToBackgroundNotifications(store, queryClient);

const App = () => {
  const [isReactQueryLoaded, setIsReactQueryLoaded] = useState(false);

  useEffect(() => {
    setupReactQuery().finally(() => setIsReactQueryLoaded(true));
  }, []);

  return (
    <SafeAreaProvider>
      <ErrorBoundary onRestart={TrackPlayer.reset}>
        <GestureHandlerRootView style={{flex: 1}}>
          <Translation>
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                {isWeb && <ReactQueryDevtools initialIsOpen={false} />}
                {isAndroid && (
                  <StatusBar backgroundColor="transparent" translucent />
                )}
                <PersistGate loading={null} persistor={persistor}>
                  {isReduxLoaded =>
                    isReactQueryLoaded && isReduxLoaded ? (
                      <ThemeProvider>
                        <ExternalWalletProvider>
                          <AppBootstrap />
                          <GlobalModalProvider>
                            <RootNavigation />
                          </GlobalModalProvider>
                        </ExternalWalletProvider>
                        <OnboardingModal />
                      </ThemeProvider>
                    ) : null
                  }
                </PersistGate>
              </QueryClientProvider>
            </Provider>
          </Translation>
        </GestureHandlerRootView>
      </ErrorBoundary>
    </SafeAreaProvider>
  );
};

export default App;
