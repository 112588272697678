import {z} from 'zod';

import {EditableAvatar, IAddress, IExternalLink} from '@/types/common';
import {ISession} from '@/types/session';
import {IDynamicTheme} from '@/types/theme';

export interface IBaseUser extends IDynamicTheme {
  id: string;
  name: string | null;
  avatarUrl?: string;
  description?: string;
  addresses: IAddress[];
  externalLinks: IExternalLink[];
}

export const UserMetadataSchema = z
  .object({
    defaultPaymentWallet: z.string().optional(),
    defaultDeliveryWallet: z.string().optional(),
    onboarding: z
      .object({
        seenCreatePasskey: z.boolean().default(false),
        seenWhatIsWallet: z.boolean().default(false),
        seenTopUpPasskey: z.boolean().default(false),
      })
      .passthrough()
      .default({
        seenCreatePasskey: false,
        seenWhatIsWallet: false,
        seenTopUpPasskey: false,
      }),
    notificationSettings: z
      .object({
        artistProfile: z
          .object({
            collects: z.boolean().default(true),
            tips: z.boolean().default(true),
          })
          .passthrough()
          .default({collects: true, tips: true}),
        collectorProfile: z
          .object({
            trackDrop: z
              .object({
                enabled: z.boolean().default(true),
                artists: z.record(z.boolean().default(false)).default({}), // map of artistId to boolean
              })
              .passthrough()
              .default({
                enabled: false,
                artists: {},
              }),
          })
          .passthrough()
          .default({trackDrop: {}}),
      })
      .passthrough()
      .default({
        artistProfile: {collects: true, tips: true},
        collectorProfile: {trackDrop: {}},
      }),
  })
  .passthrough();

export interface IUserMetadata {
  defaultPaymentWallet?: string;
  defaultDeliveryWallet?: string;
  onboarding: {
    seenCreatePasskey: boolean;
    seenWhatIsWallet: boolean;
    seenTopUpPasskey: boolean;
  };
  notificationSettings: {
    artistProfile: {
      collects: boolean;
      tips: boolean;
    };
    collectorProfile: {
      trackDrop: {
        enabled: boolean;
        artists: Record<string, boolean>;
      };
    };
  };
}

export interface IUser extends IBaseUser {
  metadata: IUserMetadata;
  artistProfile?: {
    id: string;
    slug: string;
    name: string;
    avatarUrl: string;
  } & IDynamicTheme;
}

type EditableUserKeys =
  | 'description'
  | 'predefinedThemeName'
  | 'customTheme'
  | 'name';

export type IUserUpdate = Pick<IUser, 'id'> &
  // add more editable keys here
  Partial<Pick<IUser, EditableUserKeys>> &
  EditableAvatar & {metadata?: string};

export interface IPasskeyCredential {
  credentialId: string;
  username: string;
  session: ISession;
}
