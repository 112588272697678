import {IIconProps} from '@/components/Icon';

import {IArtistWithTracks, ITrack} from './common';

export interface IGeneratorConfig {
  name: string;
  args: string[];
}

export enum IFeedGenerator {
  followedArtistsCollaborativeFiltering = 'followed-artists-collaborative-filtering',
  followedArtistTracks = 'followed-artist-tracks',
}

export enum IFeedEntityType {
  'track' = 'track',
  'artist' = 'artist',
  'refill' = 'refill',
  'message' = 'message',
  'localMessage' = 'localMessage',
}

export type IFeedUserAction = 'like' | 'hide' | null;

export interface IFeedItemRaw {
  id: string;
  entityId: string;
  entityType: IFeedEntityType;
  userId: string;
  insertionId?: number;
  userAction?: IFeedUserAction;
  generator?: IFeedGenerator;
  updatedAtTime: string;
  createdAtTime: string;
  message?: IFeedMessage;
  position?: number;
}

export type IFeedItem =
  | IFeedItemWithTrack
  | IFeedItemWithArtist
  | IMessageFeedItem
  | IRefillFeedItem;

export interface IFeedItemWithTrack extends IFeedItemRaw {
  entityType: IFeedEntityType.track;
  track: ITrack;
}

export interface IFeedItemWithArtist extends IFeedItemRaw {
  entityType: IFeedEntityType.artist;
  artist: IArtistWithTracks;
}

export interface IMessageFeedItem extends IFeedItemRaw {
  entityType: IFeedEntityType.message;
  message: IFeedMessage;
}

export interface IRefillFeedItem extends IFeedItemRaw {
  entityType: IFeedEntityType.refill;
}

export interface ILocalMessageFeedItem {
  id: string;
  entityType: IFeedEntityType.localMessage;
  localMessageId:
    | 'spinampWelcome'
    | 'feedWelcome'
    | 'popularArtists'
    | 'spyWelcome'
    | 'emptySpyFeed'
    | 'notificationsOnboarding';
  generator?: IFeedItemRaw['generator'];
  // no message here; we store ILocalMessageFeedItem's messages locally and can find them just by localMessageId
}

interface IFeedMessage {
  iconProps: IIconProps;
  title: string;
  body: string;
  actionText: string;
  actionLink: string;
}

export const feedItemFilterableProperties = [
  'id',
  'entityId',
  'entityType',
  'userId',
  'userAction',
  'generator',
] as const;

export type IFilterableFeedItem = Pick<
  IFeedItem,
  (typeof feedItemFilterableProperties)[number]
>;

export type Genre = {
  text: string;
  key: string;
  userId: string;
};

export const GENRES: Genre[] = [
  // These are approximately ordered by how many of each genre tag exist
  // in Spindexer's raw_tags table
  // slightly edited to make it look better wrt spacing
  {
    text: 'Hip-hop & Rap',
    key: 'Hip-hop & Rap',
    userId: '0x9461E1C594A02bB85d9bB00151B2Ee700c8F7257',
  },
  {
    text: 'Electronic',
    key: 'Electronic',
    userId: '0xdB8BD28DF510F3c3D6A5987595E1873Ee57B5253',
  },
  {
    text: 'Pop',
    key: 'Pop',
    userId: '0xCF09545e7286761cb452A97d62691E9Cd5021647',
  },
  {
    text: 'Dance & EDM',
    key: 'Dance & EDM',
    userId: '0xc0285373FD0fD997eA04A7aD1E9e1e540394cDF1',
  },
  {
    text: 'R&B & Soul',
    key: 'R&B & Soul',
    userId: '0x814f1D4E58A48d0d29eA8ed78D68bdDBFe17B41e',
  },
  {
    text: 'Indie',
    key: 'Indie',
    userId: '0xd7C6B6A14Ab8a031c5600050719E16EE16069345',
  },
  {
    text: 'Alternative Rock',
    key: 'Alternative Rock',
    userId: '0x608D06665Bbb122c93BE1D233098d95FDcdc7f3E',
  },
  {
    text: 'House',
    key: 'House',
    userId: '0x3A8efa5cfC916f2B54cF90f8BC283B73266ea434',
  },
  {
    text: 'Ambient',
    key: 'Ambient',
    userId: '0xbB18090FB486DF2D6Dd3B2f3E4b61F2Badb0E4a3',
  },
  {
    text: 'Experimental',
    key: 'Experimental',
    userId: '0xf2812Cd0627944b394D7e669C1e920d9804DbDC4',
  },
  {
    text: 'Folk & Singer-Songwriter',
    key: 'Folk & Singer-Songwriter',
    userId: '0x2Ea39250D825c156eE3115e763D483A1AF5920d7',
  },
  {
    text: 'Drum & Bass',
    key: 'Drum & Bass',
    userId: '0x7B63A3AFaF6e2908643381B3856Cb1e3A45df957',
  },
  {
    text: 'Techno',
    key: 'Techno',
    userId: '0x8e5F1F9B3784A6Fb93160C101124F41BBf0b2C4E',
  },
  {
    text: 'Trap',
    key: 'Trap',
    userId: '0xaD59351734E3da4FE4bf598164fF912d19F35727',
  },
  {
    text: 'Rock',
    key: 'Rock',
    userId: '0x61F994714f5fa63E866103Fd0B6064bd11275577',
  },
  {
    text: 'Lofi',
    key: 'Lofi',
    userId: '0x7855056313a3bB94f1AB989c5da3EB0449023B23',
  },
  {
    text: 'Soundtrack',
    key: 'Soundtrack',
    userId: '0xD36165DF1659bf655aAEc4bC1868092cAF',
  },
  {
    text: 'World',
    key: 'World',
    userId: '0x3e6bA851a79B90C192CF5502d662f300953b9B70',
  },
  {
    text: 'Deep House',
    key: 'Deep House',
    userId: '0x5E0325C866c64B4e5AF2d721719e9Baa86F97FFb',
  },
  {
    text: 'Downtempo',
    key: 'Downtempo',
    userId: '0xaBDc950D14AA8FdC84469c90EAd3122A60abF9E6',
  },
  {
    text: 'Afrobeat',
    key: 'Afrobeat',
    userId: '0x93122070EFa0f5617D13dD7DC367b1De2EF0346B',
  },
  {
    text: 'Dubstep',
    key: 'Dubstep',
    userId: '0x05A91332B76f54b0e3667FdEe1EF68815066Ac06',
  },
  {
    text: 'Metal',
    key: 'Metal',
    userId: '0xae84a704606bb299d0662001CcF0945D30f97e29',
  },
  {
    text: 'Latin',
    key: 'Latin',
    userId: '0x0159503Cbf0D7F1637cfde5C288a5256663Bd15c',
  },
  {
    text: 'Jazz & Blues',
    key: 'Jazz & Blues',
    userId: '0xDdfd9Af38A66981139485A69ff69d1c03FD8a26E',
  },
  {
    text: 'Classical',
    key: 'Classical',
    userId: '0xF37CBd68ABce8677b8E1ed363cb49187577b10EA',
  },
  {
    text: 'Reggaeton',
    key: 'Reggaeton',
    userId: '0x10f1888cA0B68ec1B3Fab835Dc6C856b346804F2',
  },
  {
    text: 'Piano',
    key: 'Piano',
    userId: '0x5aD7b7BBF075502B497d802FB959A1e752880B23',
  },
  {
    text: 'Country',
    key: 'Country',
    userId: '0xA2996Ee135b7B2bbcc0cD605Ac89bbfA30fC76c2',
  },
  {
    text: 'Reggae',
    key: 'Reggae',
    userId: '0xc4Dd3a62E9132644b5edC50ef92168325E4A5508',
  },
  {
    text: 'Dancehall',
    key: 'Dancehall',
    userId: '0x0be8b810697a38c416FB406653b5BD00C1Be4529',
  },
  {
    text: 'Trance',
    key: 'Trance',
    userId: '0xC5E3E2581Ded7B039Cb2dEff6fF4eD8Cc1E2088A',
  },
  {
    text: 'Disco',
    key: 'Disco',
    userId: '0xC85F9124dF6C9921cc25569D091b385DaADe6472',
  },
  {
    text: 'Triphop',
    key: 'Triphop',
    userId: '0x4F8DEfE670DCc7e7713303f0D9F394cFE4A53D01',
  },
  {
    text: 'Hyperpop',
    key: 'Hyperpop',
    userId: '0x770af47c9e136a90F4C498726e87C1e4B5E9DBFC',
  },
  {
    text: 'Podcasts',
    key: 'Podcast',
    userId: '0x2f83a912298A7b1378ea3aCa8Ac971CB797f5800',
  },
];
