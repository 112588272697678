import {Sentry} from '@/services/sentry';
import {IArtistUserFollow} from '@/types/follows';
import {ISigner} from '@/types/session';

import {submitEntityFieldUpdate} from './entityFieldUpdates';

export const addArtistUserFollow = async (
  follow: IArtistUserFollow,
  signer: ISigner,
) => {
  try {
    await submitEntityFieldUpdate(
      'artist_user_follows',
      follow,
      signer.signMessage,
      'upsert',
    );
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        context: `Error adding spindexer artist follow for ${follow.artistId}`,
        userId: follow.userId,
        artistId: follow.artistId,
      },
    });
  }
};

export const deleteArtistUserFollow = async (id: string, signer: ISigner) => {
  try {
    await submitEntityFieldUpdate(
      'artist_user_follows',
      {id},
      signer.signMessage,
      'delete',
    );
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        context: `Error deleting spindexer artist follow for ${id}`,
        id: id,
      },
    });
  }
};
