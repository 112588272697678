import {LinkingOptions} from '@react-navigation/native';

import {config} from '@/constants/config';
import {RootStackParams, Routes} from '@/types/routes';

import {getInitialURL, subscribe} from './notificationsLinking';

export const linkingConfig: LinkingOptions<RootStackParams> = {
  prefixes: [config.WEB_APP_URL, config.BRANCH_APP_URL, `${config.APP_URL}://`],
  config: {
    initialRouteName: Routes.MainNavigation,
    screens: {
      [Routes.MainNavigation]: {
        initialRouteName: Routes.Tabs,
        screens: {
          [Routes.Tabs]: {
            screens: {
              [Routes.Channels]: {
                path: 'channels',
              },
              [Routes.ExploreNavigation]: {
                initialRouteName: Routes.Explore,
                path: 'explore',
                screens: {
                  [Routes.Explore]: '',
                  [Routes.Artists]: 'artists',
                  [Routes.LatestTracks]: 'latest',
                  [Routes.TrendingTracks]: 'trending-tracks',
                  [Routes.TrendingPlaylists]: 'trending-playlists',
                },
              },
              [Routes.LibraryNavigation]: {
                initialRouteName: Routes.Library,
                path: 'library',
                screens: {
                  [Routes.Library]: {
                    path: '/:category?/:subcategory?',
                    parse: {
                      category: decodeURIComponent,
                      subcategory: decodeURIComponent,
                    },
                  },
                },
              },
              [Routes.SearchNavigation]: {
                initialRouteName: Routes.Search,
                path: 'search',
                parse: {
                  query: decodeURIComponent,
                },
                screens: {
                  [Routes.Search]: '',
                },
              },
            },
          },
          [Routes.ReleasesForYou]: {
            path: 'releases-for-you',
          },
          [Routes.Artist]: {
            path: 'artist/:slugOrId/:tab?',
            parse: {
              slugOrId: decodeURIComponent,
              tab: decodeURIComponent,
            },
          },
          [Routes.ArtistEdit]: {
            path: 'artist/:slugOrId/edit',
            parse: {
              slugOrId: decodeURIComponent,
            },
          },
          [Routes.ArtistThemeEdit]: {
            path: 'artist/:slugOrId/theme-edit',
            parse: {
              slugOrId: decodeURIComponent,
            },
          },
          [Routes.Track]: {
            path: 'track/:slug',
            parse: {
              slug: decodeURIComponent,
              autoplay: decodeURIComponent,
              redirect: decodeURIComponent,
            },
          },
          [Routes.Collector]: {
            path: 'collector/:slug/:tab?',
            parse: {
              slug: decodeURIComponent,
              tab: decodeURIComponent,
            },
          },
          [Routes.CollectorEdit]: {
            path: 'collector/:slug/edit',
            parse: {
              slug: decodeURIComponent,
            },
          },
          [Routes.CollectorThemeEdit]: {
            path: 'collector/:slug/theme-edit',
            parse: {
              slug: decodeURIComponent,
            },
          },
          [Routes.ImpersonatingReleasesForYou]: {
            path: 'collector/:slug/releases-for-you',
            parse: {
              slug: decodeURIComponent,
            },
          },
          // Fallback for Legacy screen - it now points to the same screen as Routes.Collector
          [Routes.Collection]: {
            path: 'collection/:slug/:tab?',
            parse: {
              slug: decodeURIComponent,
              tab: decodeURIComponent,
            },
          },
          [Routes.Playlist]: {
            path: 'playlist/:id',
            parse: {
              id: decodeURIComponent,
            },
          },
          [Routes.PlaylistCurators]: {
            path: 'playlist-curators/:id',
            parse: {
              id: decodeURIComponent,
            },
          },
          [Routes.Platform]: {
            path: 'platform/:platformId',
            parse: {
              platformId: decodeURIComponent,
            },
          },
          [Routes.ActivityFeed]: {
            path: 'activity-feed',
          },
          [Routes.ThemesSettings]: {
            path: 'themes',
          },
          [Routes.NotificationSettings]: {
            path: 'notification-settings',
          },
          [Routes.Wallets]: {
            path: 'wallets',
          },
          [Routes.WalletsSettings]: {
            path: 'wallets-settings',
          },
          [Routes.Wallet]: {
            path: 'wallet/:id',
            parse: {
              id: decodeURIComponent,
            },
          },
          [Routes.DropOnSpinamp]: {
            path: 'drop-on-spinamp',
          },
          [Routes.TipsSettings]: {
            path: 'tips-settings',
          },
        },
      },
      [Routes.Player]: 'player',
      [Routes.Queue]: 'queue',
      [Routes.AddToPlaylist]: {
        path: 'add-to-playlist/:id',
        parse: {
          id: decodeURIComponent,
        },
      },
      [Routes.SuggestToPlaylist]: {
        path: 'suggest-to-playlist/:id',
        parse: {
          id: decodeURIComponent,
        },
      },
      [Routes.PlaylistCollaborators]: {
        path: 'playlist-collaborators/:id',
        parse: {
          id: decodeURIComponent,
        },
      },
      [Routes.SpinampWrapped]: {
        path: 'wrapped/:userSlugOrId?',
        parse: {
          userId: decodeURIComponent,
        },
      },
      [Routes.Login]: {
        path: 'login',
      },
      [Routes.Collect]: {
        path: 'collect/:slug?',
        parse: {
          slug: decodeURIComponent,
        },
      },
      [Routes.Transfer]: {
        path: 'transfer/:slug?',
        parse: {
          transferId: decodeURIComponent,
          slug: decodeURIComponent,
          contract: decodeURIComponent,
          from: decodeURIComponent,
          tokenId: decodeURIComponent,
          tokenType: decodeURIComponent,
          chainId: parseInt,
          to: decodeURIComponent,
        },
      },
      [Routes.CryptoTransfer]: {
        path: 'crypto-transfer/:slug?',
        parse: {
          transferId: decodeURIComponent,
          from: decodeURIComponent,
          chainId: parseInt,
          to: decodeURIComponent,
        },
      },
      [Routes.SendTip]: {
        path: 'tip/:slug',
        parse: {
          slug: decodeURIComponent,
        },
      },
      [Routes.VerifyTipAddress]: {
        path: 'verify-address/:address',
        parse: {
          address: decodeURIComponent,
        },
      },
      [Routes.TopUp]: {
        path: 'top-up',
        parse: {
          address: decodeURIComponent,
          chainId: parseInt,
          amount: decodeURIComponent,
        },
      },
      [Routes.NotificationsPrompt]: {
        path: 'notifications-prompt',
        parse: {
          messageId: decodeURIComponent,
        },
      },
      [Routes.TrackModal]: {
        path: 'track-modal',
        parse: {
          slug: decodeURIComponent,
        },
      },
      [Routes.ArtistModal]: {
        path: 'artist-modal',
        parse: {
          slug: decodeURIComponent,
        },
      },
    },
  },
  getInitialURL,
  subscribe,
};
