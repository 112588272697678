import {useQueryClient, useMutation} from '@tanstack/react-query';
import {useEffect} from 'react';

import {fetchExchangeRates} from '@/api/crypto';
import {fetchAllPlatforms} from '@/api/platforms';
import {usePlayerManager} from '@/hooks/usePlayerManager';
import {usePushNotifications} from '@/hooks/usePushNotifications';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useSyncEvents} from '@/hooks/useSyncEvents';
import {useBalanceWatcher} from '@/modules/ExternalWallet';
import {useCodePushSyncQuery} from '@/queries/codepush';
import {usePlatformsQuery} from '@/queries/platforms';
import {useCustomThemesConfigQuery, useThemesQuery} from '@/queries/themes';
import {useUserProfilesQuery} from '@/queries/user';
import {Sentry} from '@/services/sentry';
import {SplashScreen} from '@/services/splashScreen';
import {restorePlayerState} from '@/store/player';
import {
  initializeAnonUser,
  selectActiveUserSession,
  setSignInProcessStep,
} from '@/store/user';
import {QueryKeys} from '@/types/queryKeys';
import {analytics} from '@/utils/analytics';

import {useResumePausedMutations} from './useResumePausedMutations';
import {useSyncPlaylists} from './useSyncPlaylists';

export const useAppBootstrap = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  useResumePausedMutations();
  useUserProfilesQuery(true);
  usePlatformsQuery(true);
  useSyncPlaylists();
  useSyncEvents();
  usePlayerManager();
  useThemesQuery(true);
  useCustomThemesConfigQuery(true);
  usePushNotifications();
  useCodePushSyncQuery(true);

  useBalanceWatcher();

  const activeUserSession = useAppSelector(selectActiveUserSession);

  const initializeUserMutation = useMutation({
    mutationFn: () => dispatch(initializeAnonUser()),
  });

  useEffect(() => {
    initializeUserMutation.mutate();
    dispatch(setSignInProcessStep('idle'));
    dispatch(restorePlayerState());
    queryClient.prefetchQuery({
      queryKey: [QueryKeys.platforms],
      queryFn: fetchAllPlatforms,
      retry: false,
    });
    queryClient.prefetchQuery({
      queryKey: [QueryKeys.exchangeRates],
      queryFn: fetchExchangeRates,
    });

    SplashScreen.hide();
  }, []);

  useEffect(() => {
    analytics.applicationLoaded();
  }, []);

  useEffect(() => {
    if (activeUserSession) {
      if (!activeUserSession.isAnon) {
        analytics.identify(activeUserSession.userId);
      }
      Sentry.setUser({id: activeUserSession.userId});
    }
    return () => {
      if (activeUserSession && !activeUserSession.isAnon) {
        analytics.reset();
      }
    };
  }, [activeUserSession]);
};
