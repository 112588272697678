import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';

import Icon, {IIconProps} from '@/components/Icon';
import Text from '@/components/Text/Text';
import Touchable from '@/components/Touchable/Touchable';
import {useThemedStyles} from '@/theme';

import {styles} from './MenuItem.style';

export const MenuItem: FC<{
  onPress: () => void;
  icon: IIconProps;
  isActive?: boolean;
  disabled?: boolean;
  text?: string;
  textId?: string;
  suffixComponent?: ReactNode;
}> = ({
  onPress,
  icon,
  isActive = false,
  disabled = false,
  text,
  textId,
  suffixComponent,
}) => {
  const style = useThemedStyles(styles);

  return (
    <Touchable
      style={[
        style.firstLevelItem,
        style.item,
        disabled && style.item_disabled,
        isActive && style.item_active,
      ]}
      disabled={disabled}
      hoveredStyle={style.item_hover}
      pressedStyle={style.item_pressed}
      onPress={onPress}>
      <View style={style.firstLevelItemIcon}>
        <Icon size={20} {...icon} />
      </View>
      <Text id={textId} numberOfLines={1} weight="semibold" flex>
        {text}
      </Text>

      {suffixComponent}
    </Touchable>
  );
};
